import { ButtonProps } from '@mui/material/Button';

import LinkButton from './components/LinkButton';
import { StyledButton } from './styled';

const Button = ({ className, ...rest }: ButtonProps) => {
  return <StyledButton className={className} {...rest} />;
};

export default Button;

export { LinkButton };
