import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { DialogTitle } from '@components/Dialog';

import { StyledDialog } from '../../../screens/ApiKeysScreen/styled';
import { theme } from '../../../theme';

type Props = {
  isLogoutModalOpened: boolean;
  setLogoutModalOpened: (value: boolean) => void;
  handleLogout: () => void;
};

const wrapperStyles = {
  display: 'flex',
  marginBottom: 0,
};

const LogoutModal = ({ isLogoutModalOpened, setLogoutModalOpened, handleLogout }: Props) => {
  const { t } = useTranslation('logoutDialog');

  const toogleModal = () => setLogoutModalOpened(!isLogoutModalOpened);

  return (
    <StyledDialog open={isLogoutModalOpened}>
      <DialogTitle title={t('title')} onClose={toogleModal} />
      <DialogContent sx={{ width: 380 }}>
        <Typography sx={{ margin: theme.spacing(2, 0) }}>{t('description')}</Typography>
        <Box style={wrapperStyles}>
          <Button
            sx={{ marginRight: theme.spacing(2) }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            {t('submit')}
          </Button>
          <Button fullWidth variant="outlined" color="primary" onClick={toogleModal}>
            {t('cancel')}
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export { LogoutModal };
