import Box from '@mui/material/Box';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

const PREFIX = 'Dialog';

export const classes = {
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`,
};

export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    background: 'rgba(0, 0, 0, 0.4)',
    '&.blurred': {
      backdropFilter: `blur(${theme.spacing(4)})`,
    },
  },

  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
  },
  '& .MuiDialogContent-root': {
    padding: 0,
    '& p': {
      color: theme.palette.grey[500],
      fontSize: 14,
    },
    '& > div': {
      marginBottom: 20,
    },
  },

  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}));

export const StyledTitleWrapper = styled(Box)(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[200],
  letterSpacing: -1,
}));

export const StyledTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
  fontSize: 20,
  color: theme.palette.primary.black,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  lineHeight: '24px',
  [`& .${classes.closeButton}`]: {
    padding: 0,
    '& svg': {
      fontSize: 16,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}));
