import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import './index.css';
import App from './App';
import 'unorm';
import 'element-closest-polyfill';
import AuthWrapper from './AuthWrapper';
import ThemeWrapper from './ThemeWrapper';

const container = document.getElementById('root');
const root = createRoot(container!);

/* tslint:disable */
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: `${process.env.REACT_APP_CLIENT_NAME}-${process.env.REACT_APP_ENVIRONMENT}`,
    release: `planetview-frontend@${process.env.REACT_APP_GIT_SHA}`,
  });
}
/* tslint:enable */

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <AuthWrapper>
          <ThemeWrapper>
            <App />
          </ThemeWrapper>
        </AuthWrapper>
      }
    />,
  ),
);

root.render(<RouterProvider router={router} />);
