import { Suspense, lazy } from 'react';

import { Grid } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import CircularLoader from '@components/CircularLoader';
import RouteMessageWrapper from '@components/RouteMessageWrapper';
import { HoldingsProvider } from '@context/holdings';
import { UploadPreviewProvider } from '@context/upload-preview';
import { useUserProperties } from '@context/userProperties';

import LoginScreen from './screens/LoginScreen';
const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const PortfolioScreen = lazy(() => import('./screens/PortfolioScreen'));
const FundScreen = lazy(() => import('./screens/FundScreen'));
const ScenariosScreen = lazy(() => import('./screens/ScenariosScreen'));
const AnalysisScreen = lazy(() => import('./screens/AnalysisScreen'));
const BookmarksScreen = lazy(() => import('./screens/BookmarksScreen'));
const ExportsScreen = lazy(() => import('./screens/ExportsScreen'));
const ModelsScreen = lazy(() => import('./screens/ModelsScreen'));
const VersionScreen = lazy(() => import('./screens/VersionScreen'));
const ApiKeysScreen = lazy(() => import('./screens/ApiKeysScreen'));
const ListedEquitiesScreen = lazy(() => import('./screens/ListedEquitiesScreen'));
const PrivateEquitiesScreen = lazy(() => import('./screens/PrivateEquitiesScreen'));
const CorporateDebtScreen = lazy(() => import('./screens/CorporateDebtScreen'));
const SovereignDebtScreen = lazy(() => import('./screens/SovereignDebtScreen'));
const RealAssetScreen = lazy(() => import('./screens/RealAssetsScreen'));
const LocationExplorerScreen = lazy(() => import('./screens/LocationExplorerScreen'));
const ModAssetScreen = lazy(() => import('./screens/ModAssetScreen'));
const CustomScenariosScreen = lazy(() => import('./screens/CustomScenariosScreen'));

const AppRoutes = () => {
  const {
    appConfig: { extraRoutes, portfolioConfig },
  } = useUserProperties();
  return (
    <Grid item xs={12} sx={{ height: '100%' }}>
      <Suspense fallback={<CircularLoader />}>
        <Routes>
          <Route
            path="/"
            element={
              <RouteMessageWrapper>
                <HomeScreen />
              </RouteMessageWrapper>
            }
          />
          {extraRoutes.includes('scenarios') && (
            <Route
              path="/scenarios-analysis"
              element={
                <RouteMessageWrapper>
                  <ScenariosScreen />
                </RouteMessageWrapper>
              }
            />
          )}
          {Boolean(portfolioConfig.assetTypes.length) && (
            <>
              <Route
                path="/portfolio"
                element={
                  <RouteMessageWrapper>
                    <PortfolioScreen />
                  </RouteMessageWrapper>
                }
              />
              <Route
                path="/portfolio/:id"
                element={
                  <UploadPreviewProvider>
                    <HoldingsProvider>
                      <RouteMessageWrapper>
                        <FundScreen />
                      </RouteMessageWrapper>
                    </HoldingsProvider>
                  </UploadPreviewProvider>
                }
              />
            </>
          )}
          <Route
            path="/analysis"
            element={
              <RouteMessageWrapper>
                <AnalysisScreen />
              </RouteMessageWrapper>
            }
          />

          <Route
            key="route-equity-detail"
            path="/equities/:id?"
            element={
              <RouteMessageWrapper>
                <ListedEquitiesScreen key="route-equity-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-private_equity-detail"
            path="/private-equities/:sectorId?/:countryCode?"
            element={
              <RouteMessageWrapper>
                <PrivateEquitiesScreen key="route-private_equity-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-corporate_debt-detail"
            path="/corporate-debt/:id?"
            element={
              <RouteMessageWrapper>
                <CorporateDebtScreen key="route-corporate_debt-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-sovereign_debt-detail"
            path="/sovereign-debt/:id?"
            element={
              <RouteMessageWrapper>
                <SovereignDebtScreen key="route-sovereign_debt-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-real_asset-detail"
            path="/real-assets/:id?"
            element={
              <RouteMessageWrapper>
                <RealAssetScreen key="route-real_asset-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-corporate_mod-detail"
            path="/corporate-mod/:id?"
            element={
              <RouteMessageWrapper>
                <ModAssetScreen key="route-corporate_mod-detail" />
              </RouteMessageWrapper>
            }
          />
          <Route
            key="route-location-explorer"
            path="/location-explorer"
            element={
              <RouteMessageWrapper>
                <LocationExplorerScreen key="route-location-explorer" />
              </RouteMessageWrapper>
            }
          />
          <Route
            path="/bookmarks"
            element={
              <RouteMessageWrapper>
                <BookmarksScreen />
              </RouteMessageWrapper>
            }
          />
          {extraRoutes.includes('exports') && (
            <Route
              path="/exports"
              element={
                <RouteMessageWrapper>
                  <ExportsScreen />
                </RouteMessageWrapper>
              }
            >
              <Route
                path=":tab"
                element={
                  <RouteMessageWrapper>
                    <ExportsScreen />
                  </RouteMessageWrapper>
                }
              />
            </Route>
          )}
          <Route
            path="/models"
            element={
              <RouteMessageWrapper>
                <ModelsScreen />
              </RouteMessageWrapper>
            }
          />
          <Route
            path="/version"
            element={
              <RouteMessageWrapper>
                <VersionScreen />
              </RouteMessageWrapper>
            }
          />
          <Route
            path="/api-keys"
            element={
              <RouteMessageWrapper>
                <ApiKeysScreen />
              </RouteMessageWrapper>
            }
          />
          <Route
            path="/scenarios"
            element={
              <UploadPreviewProvider>
                <RouteMessageWrapper>
                  <CustomScenariosScreen />
                </RouteMessageWrapper>
              </UploadPreviewProvider>
            }
          />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Grid>
  );
};

export default AppRoutes;
