import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import { Dialog } from '@components/Dialog';
import { Wrapper } from '@components/Layout';

const PREFIX = 'SettingsScreen';

export const classes = {
  table: `${PREFIX}-table`,
  requestBtn: `${PREFIX}-requestBtn`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  accessKeyText: `${PREFIX}-accessKeyText`,
  accessTokenP: `${PREFIX}-accessTokenP`,
  tokenInput: `${PREFIX}-tokenInput`,
  topbar: `${PREFIX}-topbar`,
  requestKeyBox: `${PREFIX}-requestKeyBox`,
};

export const Notification = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-standardError': {
    color: theme.palette.primary.black,
    fontSize: 16,
  },
}));

export const StyledWrapper = styled(Wrapper)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    borderRadius: 0,
    padding: 0,

    '& .tableWrapper': {
      margin: theme.spacing(2, 3),
      marginTop: 0,
      background: '#fff',
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.primary.borderGrey}`,
      overflowY: 'auto',
    },
  },

  [`& .${classes.requestKeyBox}`]: {
    margin: theme.spacing(2, 3),
    background: '#fff',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '& .keyTitle': {
      color: theme.palette.primary.black,
      marginBottom: theme.spacing(2),
      fontWeight: 500,
      letterSpacing: -1,
    },
    '& .description': {
      color: theme.palette.grey[500],
      fontSize: 14,
      paddingRight: theme.spacing(5),
    },
  },

  [`& .${classes.topbar}`]: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.primary.black,
    background: '#fff',
    marginBottom: 0,
  },

  [`& .${classes.table}`]: {
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.grey[200],
    lineHeight: theme.spacing(2),
    borderCollapse: 'collapse',
    borderSpacing: '0 1em',
    '& thead': {
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.secondary.lightGrey}`,
      fontSize: 14,
      color: theme.palette.primary.black,
      '& tr': {
        height: 48,
      },
      '& th': {
        display: 'tableCell',
        verticalAlign: 'middle',
      },
      '& th:first-of-type': {
        paddingLeft: theme.spacing(1.5),
      },
    },

    '& tbody': {
      '& tr': {
        color: theme.palette.secondary.blue,
        fontWeight: 400,

        '& td:first-of-type': {
          paddingLeft: theme.spacing(1.5),
        },
      },

      '& tr:first-of-type': {
        borderBottom: `${theme.spacing(0.5)} solid #fff`,
      },

      '& td': {
        padding: '9px 0',
      },
      '& .activeRow': {
        background: theme.palette.primary.light,
        '& td:first-of-type': {
          borderRight: 0,
          borderTopLeftRadius: theme.spacing(0.5),
          borderBottomLeftRadius: theme.spacing(0.5),
        },

        '& td:last-of-type': {
          borderLeft: 0,
          borderTopRightRadius: theme.spacing(0.5),
          borderBottomRightRadius: theme.spacing(0.5),
        },
        '& p': {
          background: theme.palette.secondary.green,
          color: theme.palette.primary.black,
          padding: theme.spacing(0.5),
          maxWidth: 40,
          borderRadius: 2,
        },

        '& button': {
          background: '#fff',
          fontWeight: 400,
        },
      },

      '& .inactiveRow': {
        '& p': {
          padding: theme.spacing(0.5),
          borderRadius: 2,
          maxWidth: 48,
          background: theme.palette.secondary.grey,
          color: theme.palette.grey[500],
        },
        '& button': {
          background: theme.palette.primary.blue,
          color: '#fff',
          minWidth: 87,
          borderColor: 'transparent',
          fontWeight: 400,
        },
      },
    },
  },

  [`& .${classes.requestBtn}`]: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.content}`]: {
    width: 400,
    marginTop: theme.spacing(1.6),
    color: theme.palette.grey[200],
    '& label': {
      marginTop: theme.spacing(3),
      color: theme.palette.primary.black,
      fontSize: 14,
      lineHeight: theme.spacing(2),
      marginBottom: 6,
    },

    '& .disclaimer': {
      fontSize: 10,
      marginBottom: theme.spacing(3),
    },
  },

  [`& .${classes.accessTokenP}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.tokenInput}`]: {
    width: '100%',
    borderRadius: 2,
    color: theme.palette.grey[500],
    marginBottom: `${theme.spacing(0.5)} !important`,
    height: 34,

    '& input': {
      padding: 0,
      paddingLeft: 6,
    },
  },
}));
