import { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { FeatureDialog } from '@components/FeatureDialog';
import { useRouteMessages } from '@context/routeMessages';
import { RouteMessage } from '@models';

const RouteMessageWrapper = ({ children }: any) => {
  const { pathname } = useLocation();
  const { messages, deleteMessageByLocation } = useRouteMessages();
  const [isModalOpened, setModalOpened] = useState(false);
  const screenPathName = useMemo(() => pathname.split('/')[1], [pathname]);
  const messagesByRoute = useMemo(
    () =>
      messages?.filter((item: RouteMessage) => {
        if (item.location === '/portfolio') {
          // Strict match for '/portfolio' so that it doesn't get rendered on holdings page
          return pathname === '/portfolio';
        }
        return new RegExp(`^${item.location}(/|$)`).test(pathname);
      }),
    [messages, pathname],
  );

  useEffect(() => {
    if (messagesByRoute.length) {
      setModalOpened(true);
    }
  }, [messagesByRoute.length, screenPathName]);

  if (!messagesByRoute.length) {
    return children;
  }

  return (
    <>
      {children}
      {isModalOpened ? (
        <FeatureDialog
          messages={messagesByRoute}
          isModalOpened={isModalOpened}
          onClose={() => setModalOpened(false)}
          onDelete={deleteMessageByLocation}
        />
      ) : null}
    </>
  );
};

export default RouteMessageWrapper;
