import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const useGetToken = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const getToken = useCallback(async () => {
    if (!isAuthenticated) {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
    return await getAccessTokenSilently();
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);
  return { getToken };
};

export default useGetToken;
