import React, { useCallback, useState } from 'react';

import { patchAccept } from '@api';
import { useUserProperties } from '@context/userProperties';
import useGetToken from '@hooks/useGetToken';

import { DisclaimerDialog } from './components/DisclaimerDialog';
import { ModelVersionDialog } from './components/ModelVersionDialog';
import {
  StyledWrapper,
  TopBar,
  FilterBox,
  ContentPaper,
  ContentGrid,
  ScrollableBox,
} from './styled';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Wrapper = ({ className, children }: Props) => {
  const {
    preferences: { disclaimer_accepted },
    setPreferences,
  } = useUserProperties();
  const newAvailableVersion = localStorage.getItem('newModelVersion');
  const [isDisclaimerModalOpened, setDisclaimerModalOpened] = useState(
    !disclaimer_accepted || false,
  );
  const [isVersionModalOpened, setVersionModalOpened] = useState(
    Boolean(newAvailableVersion) || false,
  );

  const { getToken } = useGetToken();

  const handleAccept = useCallback(async () => {
    const token = await getToken();
    patchAccept(token).then((res) => {
      setPreferences(res);
      setDisclaimerModalOpened(false);
    });
  }, [getToken, setPreferences]);

  return (
    <StyledWrapper className={className}>
      {children}
      {isDisclaimerModalOpened && (
        <DisclaimerDialog
          isDisclaimerModalOpened={isDisclaimerModalOpened}
          setDisclaimerModalOpened={setDisclaimerModalOpened}
          handleAccept={handleAccept}
        />
      )}
      {newAvailableVersion && (
        <ModelVersionDialog
          isOpened={isVersionModalOpened}
          onClose={setVersionModalOpened}
          newModel={newAvailableVersion}
        />
      )}
    </StyledWrapper>
  );
};

export { TopBar, FilterBox, ContentPaper, ContentGrid, ScrollableBox };
