import { createContext, useContext, useState } from 'react';

type ContextType = {
  expandedSidebar: boolean;
  setExpandedSidebar: (value: boolean) => void;
};

const SidebarStateContext = createContext<ContextType>({
  expandedSidebar: false,
  setExpandedSidebar: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const SidebarStateProvider = ({ children }: ProviderProps) => {
  const [expandedSidebar, setExpandedSidebar] = useState<boolean>(false);

  return (
    <SidebarStateContext.Provider value={{ expandedSidebar, setExpandedSidebar }}>
      {children}
    </SidebarStateContext.Provider>
  );
};

export const useSidebarState = () => useContext(SidebarStateContext);
