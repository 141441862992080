import { ButtonProps } from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '../styled';

const LinkButton = (props: ButtonProps & { to: string }) => {
  const navigate = useNavigate();
  return <StyledButton {...props} onClick={() => navigate(props.to)} />;
};

export default LinkButton;
