import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getUserProperties, getModels, patchModel } from '@api';
import ErrorScreen from '@components/ErrorScreen';
import FullPageLoading from '@components/FullPageLoading';
import useGetToken from '@hooks/useGetToken';
import { AppConfig, Preferences, UserInfo, Model, Entitlement } from '@models';

type Models = {
  availableModelVersions: Model[];
  modelChangedToLatest: boolean;
  setModelChangedToLatest: Dispatch<SetStateAction<boolean>>;
};

type ContextType = {
  userInfo: UserInfo;
  appConfig: AppConfig;
  preferences: Preferences;
  entitlements: Entitlement[];
  models: Models;
  setPreferences: Dispatch<SetStateAction<Preferences>>;
  patchPreferences: (data: Partial<Preferences>) => void;
};

const userInfoInitialState = {
  name: '',
  organisation: '',
  picture_url: '',
};

const appConfigInitialState = {
  securityRoutes: [],
  customAssetRoutes: [],
  defaultAnalysisMode: '',
  analysisModes: [],
  portfolioConfig: {
    fundTypes: [],
    columns: [],
    assetTypes: [],
    enableMultiFundUpload: false,
  },
  extraRoutes: [],
  appDocuments: [],
  locationExplorerModes: [],
  locationExplorerBuildingTypes: [],
  scenarioModes: [],
};

const preferencesInitialState = {
  model_version: '',
  newest_available_model_version: '',
  default_isin_for_equity_page: '',
  default_isin_for_corporate_debt_page: '',
  default_isin_for_sovereign_debt_page: '',
  default_iso3c_for_private_equity_page: '',
  default_sector_for_private_equity_page: 0,
  is_limited_functionality_demo: false,
  display_language: '',
  disclaimer_accepted: true,
  scenarios: [],
  sector_classification: '',
  filters: {},
  scenarios_with_baselines: [],
};

const modelInitialState = {
  availableModelVersions: [],
  modelChangedToLatest: false,
  setModelChangedToLatest: () => undefined,
};

const UserPropertiesContext = createContext<ContextType>({
  userInfo: userInfoInitialState,
  appConfig: appConfigInitialState,
  preferences: preferencesInitialState,
  entitlements: [],
  models: modelInitialState,
  setPreferences: () => undefined,
  patchPreferences: (data) => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const UserPropertiesProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const { i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState<UserInfo>(userInfoInitialState);
  const [appConfig, setAppConfig] = useState<AppConfig>(appConfigInitialState);
  const [preferences, setPreferences] = useState<Preferences>(preferencesInitialState);
  const [entitlements, setEntitlements] = useState<Entitlement[]>([]);

  const location = useLocation();

  const [modelChangedToLatest, setModelChangedToLatest] = useState<boolean>(false);
  const [availableModelVersions, setAvailableModelVersions] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState({
    show: false,
    text: '',
  });

  const handleUpdateModel = useCallback(
    async (model) => {
      const token = await getToken();
      patchModel(token, model.version, model.default_scenarios);
    },
    [getToken],
  );

  const setNewModelUpdates = useCallback(
    (selectedVersion: string, newModelVersion: string) => {
      const updateAvailable = newModelVersion && selectedVersion !== newModelVersion;
      if (location?.state?.appState?.from === '/login' && updateAvailable) {
        localStorage.setItem('newModelVersion', newModelVersion);
      }
    },
    [location?.state?.appState?.from],
  );

  const patchPreferences = useCallback(
    (data: Partial<Preferences>) => {
      setPreferences((prevState) => ({
        ...prevState,
        ...data,
      }));
    },
    [setPreferences],
  );

  useEffect(() => {
    (async () => {
      const token = await getToken();
      Promise.all([getUserProperties(token), getModels(token)])
        .then(([data, models]) => {
          let currentModel = models.find(
            (model) => model.version === data.preferences.model_version,
          );
          if (!currentModel) {
            // Model version in settings isn't available. Try to set `newset_available_model_version` as a current model.
            setModelChangedToLatest(true);
            currentModel = models.find(
              (model) => model.version === data.preferences.newest_available_model_version,
            );
            if (!currentModel) {
              // if previous step didn't work - just find the newest available model and set as current.
              currentModel = [...models].sort(
                (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
              )[0];
            }
            handleUpdateModel(currentModel);
          }
          setAvailableModelVersions(models as any);
          setUserInfo(data.user_info);
          setAppConfig(data.app_config);
          setPreferences({
            ...data.preferences,
            model_version: currentModel?.version,
            sector_classification: currentModel?.sector_classification || '',
          });
          setEntitlements(data.entitlements);
          setNewModelUpdates(
            data.preferences.model_version,
            data.preferences.newest_available_model_version,
          );
        })
        .catch((err) => {
          setError({ show: true, text: err?.data?.detail });
        })
        .finally(() => setLoading(false));
    })();
  }, [getToken, i18n, handleUpdateModel, setNewModelUpdates]);
  return (
    <UserPropertiesContext.Provider
      value={{
        userInfo,
        appConfig,
        preferences,
        entitlements,
        models: {
          availableModelVersions,
          modelChangedToLatest,
          setModelChangedToLatest,
        },
        setPreferences,
        patchPreferences,
      }}
    >
      {loading || (preferences.model_version === '' && !error.show) ? (
        <FullPageLoading />
      ) : (
        <>{error.show ? <ErrorScreen message={error.text} /> : children}</>
      )}
    </UserPropertiesContext.Provider>
  );
};

export const useUserProperties = () => useContext(UserPropertiesContext);
