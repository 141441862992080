export const DATA_CLEARED = 'DATA_CLEARED';
export const DELETE_HOLDING = 'DELETE_HOLDING';
export const SET_UPDATING_HOLDING = 'SET_UPDATING_HOLDING';
export const UPDATE_HOLDING = 'UPDATE_HOLDING';
export const ADD_HOLDING = 'ADD_HOLDING';
export const SET_ERROR = 'SET_ERROR';
export const SET_FILTER_BY = 'SET_FILTER_BY';
export const ON_UPLOAD = 'ON_UPLOAD';
export const SET_UPLOADING_MODE = 'SET_UPLOADING_MODE';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const SET_EDITABLE_ROW_INDEX = 'SET_EDITABLE_ROW_INDEX';
export const SET_CORPORATE_TYPE = 'SET_CORPORATE_TYPE';
export const SET_DOWNLOAD_REQUESTED = 'SET_DOWNLOAD_REQUESTED';

export const clearData = () => ({ type: DATA_CLEARED });
export const removeHolding = (index: number, resetFilterBy: boolean) => ({
  type: DELETE_HOLDING,
  index,
  resetFilterBy,
});
export const setUpdatingHolding = (rowIndex: number, dataKey: string) => ({
  type: SET_UPDATING_HOLDING,
  rowIndex,
  dataKey,
});
export const updateHolding = (index: number, resetFilterBy: boolean) => ({
  type: UPDATE_HOLDING,
  index,
  resetFilterBy,
});
export const addHolding = () => ({ type: ADD_HOLDING });
export const setError = (value: string) => ({ type: SET_ERROR, value });
export const setFilterBy = (value: string) => ({ type: SET_FILTER_BY, value });
export const handleUpload = () => ({ type: ON_UPLOAD });
export const setUploadingMode = (
  value: boolean,
  corporateType?: string,
  assetType?: string,
  refetchFlag?: boolean,
) => ({ type: SET_UPLOADING_MODE, value, corporateType, assetType, refetchFlag });
export const closeAlert = () => ({ type: CLOSE_ALERT });
export const setEditableRowIndex = (index: number) => ({ type: SET_EDITABLE_ROW_INDEX, index });
export const setCorporateType = (
  corporateType: string,
  assetType: string,
  refetchFlag: boolean,
) => ({
  type: SET_CORPORATE_TYPE,
  corporateType,
  assetType,
  refetchFlag,
});
export const setDownloadRequested = (value: boolean) => ({ type: SET_DOWNLOAD_REQUESTED, value });
