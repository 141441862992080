import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LogoIcon } from '../Icons';

const StyledLoading = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  '& svg': {
    fill: '#002c5c',
  },
}));

const FullPageLoading = () => {
  return (
    <StyledLoading>
      <LogoIcon fontSize="large" />
    </StyledLoading>
  );
};

export default FullPageLoading;
