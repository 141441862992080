import { SxProps, Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

type Props = {
  sx?: SxProps<Theme>;
};

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: theme.spacing(-2.5),
  marginLeft: theme.spacing(-2.5),
}));

const CircularLoader = ({ sx }: Props) => <StyledCircularProgress sx={sx} />;

export default CircularLoader;
