import { useCallback } from 'react';

import { DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { DialogTitle } from '@components/Dialog';
import { useUserProperties } from '@context/userProperties';
import { useModelUpdate } from '@hooks/useModelUpdate';

import Button from '../../Button';
import { classes, StyledDialog } from '../styled';

type Props = {
  isOpened: boolean;
  onClose: (values: boolean) => void;
  newModel: string;
};

const ModelVersionDialog = ({ isOpened, onClose, newModel }: Props) => {
  const { t } = useTranslation(['modelsScreen']);
  const {
    preferences: { newest_available_model_version },
  } = useUserProperties();

  const { updateModel } = useModelUpdate();

  const handleCloseModal = useCallback(() => {
    onClose(!isOpened);
    localStorage.removeItem('newModelVersion');
  }, [isOpened, onClose]);

  const handleUpdateModel = useCallback(() => {
    updateModel(newest_available_model_version);
  }, [newest_available_model_version, updateModel]);

  return (
    <StyledDialog className="none-blurred" open={isOpened}>
      <DialogTitle title={t('New model version available')} onClose={handleCloseModal} />
      <DialogContent className={clsx(classes.dialogContent, 'small-width')}>
        <Typography>{t('Activate new version', { model: newModel })}</Typography>
      </DialogContent>
      <Box className="buttonBox">
        <Button variant="contained" color="primary" fullWidth onClick={handleUpdateModel}>
          {t('Confirm')}
        </Button>
        <Button variant="outlined" color="primary" fullWidth onClick={handleCloseModal}>
          {t('No, go back', { ns: 'common' })}
        </Button>
      </Box>
    </StyledDialog>
  );
};

export { ModelVersionDialog };
