import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const PREFIX = 'ConfirmDialog';

export const classes = {
  dialog: `${PREFIX}-dialog`,
  title: `${PREFIX}-title`,
  closeButton: `${PREFIX}-closeButton`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    background: 'rgba(0, 0, 0, 0.4)',
  },

  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    maxWidth: 392,
  },

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.primary.black,
    '& h5': {
      fontWeight: 500,
      letterSpacing: -1,
    },
    '& h6': {
      fontSize: 20,
    },
  },

  [`& .${classes.closeButton}`]: {
    marginLeft: theme.spacing(2),
    '& svg': {
      fontSize: 16,
      color: theme.palette.primary.dark,
    },
    '&:hover': {
      background: 'transparent',
    },
  },

  [`& .${classes.content}`]: {
    margin: theme.spacing(2, 0, 3, 0),
    color: theme.palette.grey[500],
    width: 350,

    '& p': {
      marginTop: 0,
      fontSize: 14,
    },

    '& input': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },

    '& .analyseContent': {
      maxWidth: 380,
      '& p': {
        marginTop: 2,
      },

      '& .warningText': {
        color: theme.palette.warning.dark,
        display: 'flex',
        '& svg': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },

  [`& .${classes.actions}`]: {
    '&.flex': {
      display: 'flex',
      '& button:first-of-type': {
        marginRight: theme.spacing(2),
      },
    },
  },
}));
