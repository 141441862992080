import { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { getExports } from '@api';
import useGetToken from '@hooks/useGetToken';
import useInterval from '@hooks/useInterval';

type StatusCounts = {
  available: number;
  pending: number;
  errored: number;
};

type ContextType = {
  statusCounts: StatusCounts;
  downloadsAvailable: boolean;
  polling: boolean;
  checkStatus: () => void;
};

const DownloadsNotificationContext = createContext<ContextType>({
  statusCounts: {
    available: 0,
    pending: 0,
    errored: 0,
  },
  downloadsAvailable: false,
  polling: false,
  checkStatus: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const DownloadsNotificationProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const [statusCounts, setStatusCounts] = useState<StatusCounts>({
    available: 0,
    pending: 0,
    errored: 0,
  });
  const [downloadsAvailable, setDownloadsAvailable] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);
  const checkStatus = useCallback(async () => {
    const token = await getToken();
    getExports(token).then((data) => {
      const { available, pending, errored } = data
        .filter((i) => i.mine)
        .reduce(
          (acc: { [index: string]: number }, i) => {
            if (!i.downloaded) {
              acc[i.status] = acc[i.status] ? acc[i.status] + 1 : 1;
            }
            return acc;
          },
          { available: 0, pending: 0, errored: 0 },
        );
      setStatusCounts({ available, pending, errored });
      if (available) {
        setDownloadsAvailable(true);
      } else {
        setDownloadsAvailable(false);
      }
      setPolling(pending > 0);
    });
  }, [getToken]);

  useInterval(
    () => {
      checkStatus();
    },
    polling ? 60000 : null,
  );

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  return (
    <DownloadsNotificationContext.Provider
      value={{ statusCounts, downloadsAvailable, polling, checkStatus }}
    >
      {children}
    </DownloadsNotificationContext.Provider>
  );
};

export const useDownloadsNotification = () => useContext(DownloadsNotificationContext);
