import { useCallback, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import useSidebarItems from '@hooks/useSidebarItems';
import { UserInfo } from '@models';

import { classes } from '../styled';
import { LogoutModal } from './LogoutModal';
import { NavDisclaimer } from './NavDisclaimer';
import { NavItem } from './NavItem';
import { NavTooltip } from './NavTooltip';

type NavigationTabsProps = {
  userInfo: UserInfo;
  expanded: boolean;
};

const NavigationTabs = ({ userInfo, expanded }: NavigationTabsProps) => {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isLogoutModalOpened, setLogoutModalOpened] = useState(false);
  const { t } = useTranslation(['sidemenu', 'disclaimer']);
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { menuItems, footerLinks } = useSidebarItems();
  const autoHideTitles = !expanded ? classes.hiddenText : '';
  const collapsedStyles = !expanded ? 'collapsed' : '';
  const { pathname } = useLocation();

  const toogleMenu = useCallback(() => setMenuOpened(!isMenuOpened), [isMenuOpened]);

  const onClickApiKeyMenuItem = () => {
    toogleMenu();
    navigate('/api-keys');
  };

  useEffect(() => {
    if (!expanded) {
      setMenuOpened(false);
    }
  }, [expanded]);

  const handleLogout = useCallback(() => {
    setLogoutModalOpened(false);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const handleOpenModal = useCallback(() => {
    toogleMenu();
    setLogoutModalOpened(true);
  }, [toogleMenu]);

  return (
    <>
      <Box className={clsx(classes.navWrapper, collapsedStyles)}>
        <nav>
          {menuItems.map(({ title, to, extraClassName, icon, isDropdown, links }) => {
            return (
              <NavItem
                key={title}
                title={title}
                icon={icon}
                to={to}
                extraClassName={extraClassName}
                autoHideTitles={autoHideTitles}
                isDropdown={isDropdown}
                links={links}
                collapsedStyles={collapsedStyles}
                expanded={expanded}
              />
            );
          })}
        </nav>
      </Box>
      <Box className={clsx(classes.account, collapsedStyles)}>
        {footerLinks.map(({ title, to, icon, extraClassName, newUpdates }) => {
          return (
            <NavItem
              key={title}
              title={title}
              icon={icon}
              to={to}
              extraClassName={extraClassName}
              autoHideTitles={autoHideTitles}
              expanded={expanded}
              newUpdates={newUpdates}
            />
          );
        })}
        <NavDisclaimer
          expanded={expanded}
          autoHideTitles={autoHideTitles}
          setMenuOpened={setMenuOpened}
        />
        <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
          <Box className="menu-wrapper">
            <NavTooltip
              placement="right-end"
              sx={expanded ? { marginLeft: '16px!important' } : {}}
              open={isMenuOpened}
              title={
                <Box className="menu-tooltip">
                  <p
                    className={clsx(pathname === '/api-keys' && 'active')}
                    onClick={onClickApiKeyMenuItem}
                  >
                    {t('API keys')}
                  </p>
                  <p onClick={handleOpenModal}>{t('Log out')}</p>
                </Box>
              }
            >
              <Box className={clsx(classes.company, collapsedStyles)}>
                <Box
                  className={classes.companyLogo}
                  onClick={toogleMenu}
                  style={{ backgroundImage: `url(${userInfo.picture_url})` }}
                />
                <Typography
                  className={clsx(classes.companyName, autoHideTitles)}
                  variant="body2"
                  title={userInfo.organisation}
                >
                  {userInfo.organisation}
                </Typography>
              </Box>
            </NavTooltip>
          </Box>
        </ClickAwayListener>
        <LogoutModal
          setLogoutModalOpened={setLogoutModalOpened}
          isLogoutModalOpened={isLogoutModalOpened}
          handleLogout={handleLogout}
        />
      </Box>
    </>
  );
};

export default NavigationTabs;
