import { useRef } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@components/Button';

import { DownloadReadyIcon, CloseIcon } from '../Icons';
import { StyledNotification } from './styled';
import { theme } from '../../theme';

type Props = {
  data: any[];
  handleClose: (fileName: number) => void;
};

const DownloadNotification = ({ data, handleClose }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();
  return data.map((item, index) => (
    <StyledNotification
      ref={ref}
      key={index}
      sx={{ top: index * ((ref.current?.clientHeight || 0) + 8), marginTop: theme.spacing(1) }} // these values are for positioning one below another with some gap
    >
      <Box component="header">
        <Box className="title-box">
          <DownloadReadyIcon />
          <Typography>{t('Download ready')}</Typography>
        </Box>
        <CloseIcon
          className="close-icon"
          fill={theme.palette.grey[500]}
          onClick={() => handleClose(item.id)}
        />
      </Box>
      <Box className="contentBox">
        <Typography>{`${item.name}.${item.file_format}`}</Typography>
        <Typography>{t('View this in your downloads tab')}</Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/exports', { state: { closeCreationForm: true } })}
        >
          {t('View download')}
        </Button>
      </Box>
    </StyledNotification>
  ));
};

export { DownloadNotification };
