import { Model, SortType } from '@models';
import { sortData } from '@utils';

type State = {
  loading: boolean;
  data: Model[];
  error: string;
  switching: string;
  sorting: {
    key: keyof Model;
    dir: SortType;
  };
};

type Action =
  | { type: 'setLoading'; value: boolean }
  | { type: 'dataFetched'; data: Model[] }
  | { type: 'setError'; value: string }
  | { type: 'setSwitching'; value: string }
  | { type: 'sortData'; sorting: { key: keyof Model; dir: SortType }; fieldType: string };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setLoading':
      return {
        ...state,
        loading: action.value,
      };
    case 'dataFetched':
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case 'setError':
      return {
        ...state,
        loading: false,
        error: action.value,
      };
    case 'sortData': {
      const data = sortData({ data: state.data, sorting: action.sorting, type: action.fieldType });
      return {
        ...state,
        data,
        sorting: action.sorting,
      };
    }
    default:
      return state;
  }
};
